import Vue from 'vue'
import Vuex from 'vuex'
import { CHANGE_LANGUAGE, SET_LANGUAGE, CHANGE_LOADING } from './constants/login'
import { CHANGE_ACCESS_ORIGIN, SET_ACCESS_ORIGIN, SET_IS_APPLICATION_LOADING } from './constants/app'
import login from './modules/login/index'
import store from './modules/store/index'
import checkout from './modules/checkout/index'
import migration from './modules/migration/index'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        // TODO: Ajustar aqui para aceitar clientes internacionais, não deve buscar do navegador, mas do idioma definido de alguma forma pelo próprio cliente, sendo o navegador a última opção se nada for definido previamente
        language: window.localStorage.lang ?? process.env.VUE_APP_I18N_LOCALE, // window.navigator.language,
        isApplicationLoading: false,
        accessOrigin: null
    },
    getters: {
        language: state => state.language,
        isApplicationLoading: state => state.isApplicationLoading,
        accessOrigin: state => state.accessOrigin,
        isDesktop: state => {
            return state?.accessOrigin === 'desktop'
        },
        eventGtm: (state, getters) => (eventName) => {
            const dataLayer = window.dataLayer || []
            const isDesktop = getters.isDesktop
            dataLayer.push({
                event: isDesktop ? `desktop_${eventName}` : eventName
            })
        }
    },
    mutations: {
        [SET_LANGUAGE]: (state, newLanguage) => state.language = newLanguage,
        [SET_IS_APPLICATION_LOADING]: (state, isApplicationLoading) => state.isApplicationLoading = isApplicationLoading,
        [SET_ACCESS_ORIGIN]: (state, newAccessOrigin) => state.accessOrigin = newAccessOrigin,
    },
    actions: {
        [CHANGE_LANGUAGE]: async ({ commit }, language) => {
            commit(SET_LANGUAGE, language);
        },
        [CHANGE_LOADING]: async ({ commit }, status) => {
            commit(SET_IS_APPLICATION_LOADING, status);
        },
        [CHANGE_ACCESS_ORIGIN]: async ({ commit }, data) => {
            commit(SET_ACCESS_ORIGIN, data);
        },
    },
    modules: {
        login,
        store,
        checkout,
        migration
    }
})
