import { STORE, CHECKOUT_INFOS } from '@/store/constants/store';

export default {
  [STORE]: state => state.store,
  [CHECKOUT_INFOS]: state => state.checkoutInfos,
  paymentStatusUser: state => state?.store?.storePaymentState,
  person: state => state.store?.person,
  firstName: state => state?.store?.person?.owner.split(/(\s).+\s/).join(''),
  billingAddress: state => state?.store?.storeAddress,
  phones: state => state?.store?.phones,
  storeProfile: state => state?.store?.store,
  subscription: state => state?.store?.subscription,
  getCountry: state => state?.store?.storeAddress?.country,
  isTrial: state => state?.store?.subscription?.is_trial,
  isNonDefaulter: state => {
    const storePaymentState = state?.store?.storePaymentState
        return storePaymentState === 'subscriber_bankslip_paying' ||
            storePaymentState === 'subscriber_credit_card_paying'
  },
  intercomData: state => {
    if (state.store?.store?.shopCode) {
      return {
        language_override: 'pt',
        email: state.store?.store?.account,
        user_id: state.store?.store?.shopCode + '.NexServ',
        name: state.store?.person?.owner,
        admin: 1,
        conta: state.store?.store?.account,
        lang: 'pt',
        country: state.store?.billingAddress?.country,
        company: {
          id: state.store?.store?.shopCode,
          name: state.store?.store?.store_name,
          email: state.store?.store?.account,
          conta: state.store?.store?.account
        }
      }
    }
  }
}
