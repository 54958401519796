import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    bankList: [
      {
        code: 'banco_agrario',
        name: 'Banco Agrario'
      },
      {
        code: 'banco_av_villas',
        name: 'Banco Av Villas'
      },
      {
        code: 'banco_bbva_colombia_s.a.',
        name: 'Banco Bbva Colombia S.A.'
      },
      {
        code: 'banco_caja_social',
        name: 'Banco Caja Social'
      },
      {
        code: 'banco_colpatria',
        name: 'Banco Colpatria'
      },
      {
        code: 'banco_cooperativo_coopcentral',
        name: 'Banco Cooperativo Coopcentral'
      },
      {
        code: 'banco_corpbanca_s.a',
        name: 'Banco Corpbanca S.A'
      },
      {
        code: 'banco_davivienda',
        name: 'Banco Davivienda'
      },
      {
        code: 'banco_de_bogota',
        name: 'Banco De Bogota'
      },
      {
        code: 'banco_de_occidente',
        name: 'Banco De Occidente'
      },
      {
        code: 'banco_falabella_',
        name: 'Banco Falabella '
      },
      {
        code: 'banco_gnb_sudameris',
        name: 'Banco Gnb Sudameris'
      },
      {
        code: 'banco_pichincha_s.a.',
        name: 'Banco Pichincha S.A.'
      },
      {
        code: 'banco_popular',
        name: 'Banco Popular'
      },
      {
        code: 'banco_procredit',
        name: 'Banco Procredit'
      },
      {
        code: 'bancolombia',
        name: 'Bancolombia'
      },
      {
        code: 'bancoomeva_s.a.',
        name: 'Bancoomeva S.A.'
      },
      {
        code: 'citibank_',
        name: 'Citibank '
      },
      {
        code: 'itau',
        name: 'Itau'
      },
      {
        code: 'nequi',
        name: 'Nequi'
      }
    ],
    bankSelected: null
  },
  actions,
  getters,
  mutations,
  namespaced: true
}
