import { SET_BANK_LIST, SET_BANK_SELECTED, UPDATE_BANK_LIST } from '@/store/constants/eft'
import axios from 'axios'

export default {
  [UPDATE_BANK_LIST]: async ({ commit }) => {
    const integrationKey = process.env.VUE_APP_EBANX_INTEGRATION_KEY
    const baseUrl = `${process.env.VUE_APP_EBANX_BANK_URL}?integration_key=${integrationKey}&country=co`

    const response = await axios.get(baseUrl).catch(error => { console.log(error) })
    commit(SET_BANK_LIST, response?.data)
  },
  [SET_BANK_SELECTED]: async ({ commit }, bankSelected) => {
    return commit(SET_BANK_SELECTED, bankSelected)
  }
}
