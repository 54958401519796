import { EXEC_AUTH, SET_CONTACT_ID, SET_TOKEN, EXEC_WIDGET } from '@/store/constants/opens'
import axios from 'axios'

export default {
  [EXEC_AUTH]: async ({ commit }, { name, email }) => {
    const chatApp = document.getElementById('yunique-chat-app')

    if (chatApp) {
      return
    }

    const baseUrl = process.env.VUE_APP_OPENS_AUTH_URL
    const companyId = process.env.VUE_APP_OPENS_CHAT_ID

    try {
      const response = await axios.post(baseUrl, { name, email, companyId })

      const { contactId, token } = response.data

      commit(SET_TOKEN, token)
      commit(SET_CONTACT_ID, contactId)
    } catch (error) {
      return {}
    }
  },
  [EXEC_WIDGET]: ({ state }, { language }) => {
    const chatApp = document.getElementById('yunique-chat-app')

    if (chatApp) {
      return
    }

    const baseUrl = process.env.VUE_APP_OPENS_WIDGET_URL
    const brChatID = process.env.VUE_APP_OPENS_CHAT_BR_ID
    const enChatID = process.env.VUE_APP_OPENS_CHAT_EN_ID
    const esChatID = process.env.VUE_APP_OPENS_CHAT_ES_ID
    const contactId = state.contactID
    const token = state.token

    if (!contactId || !token) {
      return
    }

    switch (language.toUpperCase()) {
      case 'EN':
        window.localStorage.setItem('@id-yunique', enChatID)
        break
      case 'ES':
        window.localStorage.setItem('@id-yunique', esChatID)
        break
      default:
        window.localStorage.setItem('@id-yunique', brChatID)
    }

    const widgetUrl = `${baseUrl}?contactId=${contactId}&btYunique=${token}&goToChat=true`

    const script = document.createElement('script')
    script.src = widgetUrl
    script.defer = 'defer'
    script.type = 'module'
    script.id = ''
    const div = document.createElement('div')
    div.id = 'yunique-chat-app'
    return { div, script }
  }
}
