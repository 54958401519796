import { GET_PAYMENT_METHOD, GET_PLANS_MONTHLY, GET_PLANS_NAME, GET_PLAN, GET_PLANS, GET_RECURRENCE, GET_PLAN_SELECTED_RECURRENCIES, GET_CHOSEN_PLAN, GET_CREDIT_CARD_TOKEN_ERRORS, GET_ORDER, GET_PENDING, GET_COUNTRY } from '@/store/constants/checkout'

export default {
  [GET_PLAN]: state => state.plan,
  [GET_PLANS]: state => state.plans,
  [GET_PLANS_MONTHLY]: state => state.plansMonthly,
  [GET_PLANS_NAME]: state => state.plansName,
  [GET_RECURRENCE]: state => state.recurrence,
  [GET_PAYMENT_METHOD]: state => state.paymentMethod,
  [GET_PLAN_SELECTED_RECURRENCIES]: state => state.planSelectedRecurrencies,
  [GET_CHOSEN_PLAN]: state => state.chosenPlan,
  [GET_CREDIT_CARD_TOKEN_ERRORS]: state => state.getCreditCardTokenErrors,
  [GET_ORDER]: state => state.order,
  [GET_PENDING]: state => state.pending,
  [GET_COUNTRY]: state => state.billingAddress.country
}
