import { AbbreviationLang } from '@/constants'
import AxiosHttp from '@/services/AxiosHttp'
import { CHANGE_CHOSEN_PLAN, CHANGE_PLAN, GET_PLANS, SET_CHOSEN_PLAN, SET_PLAN, SET_PLANS } from '@/store/constants/checkout'
import { EXEC_MIGRATION, SET_MIGRATION_RESULT, SIMULATE_MIGRATION } from '@/store/constants/migration'
import Utils from '@/utils/Utils'

export default {
  [CHANGE_PLAN]: async ({ commit }, planData) => {
    return commit(SET_PLAN, planData)
  },
  [GET_PLANS]: async ({ commit }, data) => {
    const lang = AbbreviationLang[data.lang]
    const query = `?&country=${lang}`
    const response = await AxiosHttp.get('/migration/available-plans' + query)
      .catch(error => {
        throw error
      })
    if (response) {
      const { currentPlan, availableToMigration } = getAvailablePlans(Utils.serializePlans(response), data)
      commit(SET_PLAN, currentPlan)
      const orderedArray = availableToMigration
      return commit(SET_PLANS, orderedArray)
    }
    return response
  },
  [CHANGE_CHOSEN_PLAN]: async ({ commit }, chosenPlan) => {
    return commit(SET_CHOSEN_PLAN, chosenPlan)
  },
  [SIMULATE_MIGRATION]: async (context, plansData) => {
    const response = await AxiosHttp.post('/migration/simulate', plansData)
      .catch(error => {
        throw error
      })

    return response
  },
  [EXEC_MIGRATION]: async ({ commit }, plansData) => {
    const response = await AxiosHttp.post('/migration', plansData)
      .catch(error => {
        throw error
      })
    if (response) {
      commit(SET_MIGRATION_RESULT, response)
    }

    return response
  }
}

function getAvailablePlans (response, data) {
  const plans = Object.values(response).filter(plan => plan.price > 0)
  const currentPlan = plans.find((plan) => {
    return plan.priceId === data.actualPlanId || plan.id === data.actualPlanId
  })
  if (!currentPlan) {
    const planIndex = plans.findIndex(plan =>
      plan.legacy.priceId === data.price_id &&
      plan.recurrencePeriod === data.current_recurrence
    )
    return {
      availableToMigration: plans
        .filter(plan => plan.recurrencePeriod === data.current_recurrence),
      currentPlan: plans[planIndex]
    }
  }
  const availableToMigration = plans.filter((plan) => {
    return plan.recurrencePeriod === currentPlan.recurrencePeriod
  })
  return { currentPlan, availableToMigration }
}
