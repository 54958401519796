import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  RECURRENCE_SELECTOR_VIEW, HOME_VIEW,
  LOGIN_VIEW,
  RECOVER_PASSWORD_VIEW,
  AUTH_LAYOUT,
  MAIN_LAYOUT,
  REDEFINE_PASSWORD_VIEW,
  CANCEL_PLAN_VIEW,
  EDIT_STORE_VIEW,
  CHECKOUT_LAYOUT,
  PLAN_SELECTOR_VIEW,
  PAYMENT_METHOD_SELECTOR_VIEW,
  CHECKOUT_PROFILE_EDIT_VIEW,
  CHANGE_PAYMENT_METHOD_VIEW,
  CHANGE_TO_CREDIT_CARD,
  CHANGE_TO_BANKSLIP,
  CHECKOUT_BANKSLIP_VIEW,
  CHECKOUT_CREDIT_CARD_VIEW,
  MIGRATION_PLAN_VIEW,
  MIGRATION_CONFIRMATION_VIEW,
  MIGRATION_END_VIEW,
  LEGACY_TOKEN_VIEW,
  BANKSLIP_VIEW,
  BANKSLIP_PDF_VIEW,
  CHECKOUT_CREDIT_CARD_SUCCESS,
  UNAVAILABLE_VIEW
} from './constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/unavailable',
    name: UNAVAILABLE_VIEW,
    component: () => import(/* webpackChunkName: "esqueci-senha" */ '../views/UnavailableView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/esqueci-senha',
    name: RECOVER_PASSWORD_VIEW,
    component: () => import(/* webpackChunkName: "esqueci-senha" */ '../views/RecoverPasswordView.vue'),
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: '/recover-password/:token',
    name: REDEFINE_PASSWORD_VIEW,
    component: () => import(/* webpackChunkName: "recover-password" */ '../views/RedefinePasswordView.vue'),
    meta: {
      requiresAuth: false,
      title: 'Redefinir Senha'
    }
  },
  {
    path: '/legacy/:token/:redirect',
    name: LEGACY_TOKEN_VIEW,
    component: () => import(/* webpackChunkName: "auth-legacy" */ '../views/LegacyAuthTokenLogin.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: MAIN_LAYOUT,
    component: () => import(/* webpackChunkName: "main" */ '../views/layout/MainLayout.vue'),
    children: [
      {
        path: '/*',
        redirect: { name: HOME_VIEW },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/dashboard',
        name: HOME_VIEW,
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        meta: {
          requiresAuth: true,
          title: 'Sua assinatura'
        }

      },
      {
        path: '/boleto',
        name: BANKSLIP_VIEW,
        component: () => import(/* webpackChunkName: "bankslip" */ '../views/BankslipView.vue'),
        meta: {
          requiresAuth: true,
          title: 'Boleto'
        }

      },
      {
        path: '/pdf',
        name: BANKSLIP_PDF_VIEW,
        component: () => import(/* webpackChunkName: "bankslip" */ '../views/BankslipPDFView.vue'),
        meta: {
          requiresAuth: true,
          title: 'Boleto'
        }

      },
      {
        path: '/editar-loja',
        name: EDIT_STORE_VIEW,
        component: () => import(/* webpackChunkName: "home" */ '../views/EditStoreView.vue'),
        meta: {
          requiresAuth: true,
          title: 'Sua loja'
        }

      },
      {
        path: '/cancelar',
        name: CANCEL_PLAN_VIEW,
        component: () => import(/* webpackChunkName: "home" */ '../views/CancelPlanView.vue'),
        meta: {
          requiresAuth: true,
          title: 'Cancelar assinatura'
        }
      }
    ]
  },
  {
    path: '/auth',
    name: AUTH_LAYOUT,
    component: () => import(/* webpackChunkName: "auth" */ '../views/layout/AuthLayout.vue'),
    children: [
      {
        path: '/login',
        name: LOGIN_VIEW,
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
        meta: {
          title: 'Login'
        }
      }
    ]
  },
  {
    path: '/checkout',
    name: CHECKOUT_LAYOUT,
    requiresAuth: true,
    component: () => import(/* webpackChunkName: "checkout" */ '../views/layout/CheckoutLayout.vue'),
    children: [
      {
        path: '/assinar',
        name: PLAN_SELECTOR_VIEW,
        component: () => import(/* webpackChunkName: "plan-selector" */ '../views/PlanSelectorView.vue'),
        meta: {
          title: 'Escolher plano'
        }
      },
      {
        path: '/migrar',
        name: MIGRATION_PLAN_VIEW,
        component: () => import(/* webpackChunkName: "migration-selector" */ '../views/MigrationView.vue'),
        meta: {
          title: 'Migration'
        }
      },
      {
        path: '/migrar/confirmar',
        name: MIGRATION_CONFIRMATION_VIEW,
        component: () => import(/* webpackChunkName: "migration-confirmation" */ '../views/MigrationConfirmationView.vue'),
        meta: {
          title: 'Migration'
        }
      },
      {
        path: '/migrar/final',
        name: MIGRATION_END_VIEW,
        component: () => import(/* webpackChunkName: "migration-end" */ '../views/MigrationEndView.vue'),
        meta: {
          title: 'Migration'
        }
      },
      {
        path: '/assinar/recorrencia',
        name: RECURRENCE_SELECTOR_VIEW,
        component: () => import(/* webpackChunkName: "recurrence-selector" */ '../views/RecurrenceSelectorView.vue'),
        meta: {
          title: 'Recorrencia'
        }
      },
      {
        path: '/assinar/recorrencia/forma-de-pagamento',
        name: PAYMENT_METHOD_SELECTOR_VIEW,
        component: () => import(/* webpackChunkName: "payment-method-selector" */ '../views/PaymentMethodSelectorView.vue'),
        meta: {
          title: 'Metodo de pagamento'
        }
      },
      {
        path: '/assinar/recorrencia/cartao-de-credito',
        name: CHECKOUT_CREDIT_CARD_VIEW,
        component: () => import(/* webpackChunkName: "checkout-credit-card" */ '../views/CheckoutCreditCardView.vue'),
        meta: {
          title: 'Cartão de Crédito'
        }
      },
      {
        path: '/assinar/recorrencia/dados-para-nota-fiscal',
        name: CHECKOUT_PROFILE_EDIT_VIEW,
        component: () => import(/* webpackChunkName: "checkout-profile-edit-selector" */ '../views/CheckoutProfileEditView.vue'),
        meta: {
          title: 'NF'
        }
      },
      {
        path: '/alterar-pagamento',
        name: CHANGE_PAYMENT_METHOD_VIEW,
        component: () => import(/* webpackChunkName: "checkout-profile-edit-selector" */ '../views/ChangePaymentMethodView.vue'),
        meta: {
          title: 'NF'
        }
      },
      {
        path: '/alterar-pagamento/cartao-de-credito',
        name: CHANGE_TO_CREDIT_CARD,
        component: () => import(/* webpackChunkName: "checkout-profile-edit-selector" */ '../views/ChangeToCreditCardView.vue'),
        meta: {
          title: 'NF'
        }
      },
      {
        path: '/alterar-pagamento/boleto/:method',
        name: CHANGE_TO_BANKSLIP,
        component: () => import(/* webpackChunkName: "checkout-profile-edit-selector" */ '../views/ChangeToBankSlipView.vue'),
        meta: {
          title: 'NF'
        }
      },
      {
        path: '/assinar/recorrencia/assinatura-concluida',
        name: CHECKOUT_BANKSLIP_VIEW,
        component: () => import(/* webpackChunkName: "checkout-bankslip" */ '../views/CheckoutPaymentSlipView.vue'),
        meta: {
          title: 'Obrigado'
        }
      },
      {
        path: '/assinar/recorrencia/assinatura-success',
        name: CHECKOUT_CREDIT_CARD_SUCCESS,
        component: () => import(/* webpackChunkName: "checkout-bankslip" */ '../views/CheckoutSuccessView.vue'),
        meta: {
          title: 'Obrigado'
        }
      }
    ]

  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    Vue.nextTick(() => {
      const title = to.meta.title + ' - NEXTAR'
      document.title = title
    })
  }
})

router.beforeEach((to, _from, next) => {
  const isUnavailable = process.env.VUE_APP_IS_UNAVAILABLE
  const { query } = to

  if (query?.token) {
    window.localStorage.setItem('nex-token', query.token)
  }

  const isLogged = window.localStorage.getItem('nex-token')

  if (to.matched.some(record => record.meta.requiresAuth) && !isLogged) {
    return next({ name: LOGIN_VIEW, query: to.query })
  }
  if (to.path === '/login' && isLogged) {
    return next({ name: HOME_VIEW, query: to.query })
  }
  if (isUnavailable === 'true' && to.name !== 'UNAVAILABLE_VIEW') {
    return next({ name: 'UNAVAILABLE_VIEW', query: to.query })
  } else if (isUnavailable === 'false' && to.name === 'UNAVAILABLE_VIEW') {
    return next({ name: HOME_VIEW, query: to.query })
  }

  return next()
})

export default router
