export default class Utils {
  static calcDiscount (months, valuePerMonth, comparingValue) {
    const totalPerMonth = (months * valuePerMonth)
    return totalPerMonth - comparingValue
  }

  static calcValuePerMounth (months, total) {
    return (total / months).toFixed(0)
  }

  static observerToArray (object) {
    return Object.values(
      JSON.parse(JSON.stringify(object))
    )
  }

  static capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  static serializePlans (plan) {
    const recurrenceMapper = {
      monthly: 1,
      semiannual: 6,
      annual: 12
    }

    const serializedPlan = []

    for (const [priceProps, values] of Object.entries(plan.prices)) {
      let legacy
      if (values === null) {
        continue
      }
      for (const [recurrence, value] of Object.entries(values)) {
        if (!recurrenceMapper[recurrence] || value === null) {
          legacy = value
          continue
        }

        serializedPlan.push({
          name: `${priceProps.toUpperCase()} V3`,
          recurrencePeriod: recurrenceMapper[recurrence],
          price: value.amount,
          corrency: plan.recurrence_currency,
          priceId: value.price_id,
          legacy,
          currencySymbol: plan.currency_symbol
        })
      }
      continue
    }

    return serializedPlan.reduce((acc, cur, index) => {
      acc[index] = cur
      return acc
    }, {})
  }
}
