import { gateways, recurrences } from '@/constants'
import Gateway from '@/plugins/gateway'
import AxiosHttp from '@/services/AxiosHttp'
import { SET_IS_APPLICATION_LOADING } from '@/store/constants/app'
import { CHANGE_PLAN, EXEC_CHECKOUT, CHANGE_PLAN_SELECTED_RECURRENCIES, GET_PLANS, SET_PLAN, GET_PLANS_MONTHLY, CHANGE_RECURRENCE, SET_RECURRENCE, CHANGE_PAYMENT_METHOD_BANKSLIP, SET_PAYMENT_METHOD, SET_PLANS, SET_PLANS_MONTHLY, SET_PLAN_SELECTED_RECURRENCIES, CHANGE_CHOSEN_PLAN, SET_CHOSEN_PLAN, CREATE_CREDIT_CARD_TOKEN, SET_CREDIT_CARD_TOKEN_ERRORS, SET_CREDIT_CARD_TOKEN, CHANGE_PAYMENT_METHOD_CREDIT_CARD, SET_ORDER, SET_PENDING, CHANGE_PENDING, SET_PAYMENT_OPTIONS, CHANGE_PAYMENT_METHOD } from '@/store/constants/checkout'
import { EFT_MODULE, GET_BANK_SELECTED } from '@/store/constants/eft'
import { STORE, STORE_MODULE } from '@/store/constants/store'
import Utils from '@/utils/Utils'

export default {
  [CHANGE_PLAN]: async ({ commit }, planData) => {
    return commit(SET_PLAN, planData)
  },
  [CHANGE_RECURRENCE]: async ({ commit }, recurrenceData) => {
    return commit(SET_RECURRENCE, recurrenceData)
  },
  [CHANGE_PAYMENT_METHOD]: async ({ commit }, paymentMethodData) => {
    return commit(SET_PAYMENT_METHOD, paymentMethodData)
  },
  [CHANGE_CHOSEN_PLAN]: async ({ commit }, chosenPlan) => {
    return commit(SET_CHOSEN_PLAN, chosenPlan)
  },
  [CHANGE_PLAN_SELECTED_RECURRENCIES]: async ({ commit }, planRecurrencies) => {
    return commit(SET_PLAN_SELECTED_RECURRENCIES, planRecurrencies)
  },
  [CHANGE_PENDING]: async ({ commit }, status) => {
    commit(SET_PENDING, status)
  },
  [CREATE_CREDIT_CARD_TOKEN]: async ({ commit }, data) => {
    const { gateway } = data
    const gatewayClass = new Gateway(gateway)
    let tokens = null

    try {
      tokens = await gatewayClass.tokenize(data)
    } catch (error) {
      commit(SET_CREDIT_CARD_TOKEN_ERRORS, {
        error: true,
        errorCode: 'system',
        data: tokens
      })
      commit(SET_IS_APPLICATION_LOADING, false, { root: true })
      return tokens
    }
    if (!tokens) {
      commit(SET_CREDIT_CARD_TOKEN_ERRORS, {
        error: true,
        errorCode: 'payment',
        data: tokens
      })
      commit(SET_IS_APPLICATION_LOADING, false, { root: true })
      return tokens
    }
    const hasErrors =
      tokens &&
      tokens.response &&
      tokens.response.data &&
      tokens.response.data.errors.length > 0

    if (hasErrors) {
      commit(SET_CREDIT_CARD_TOKEN_ERRORS, {
        error: true,
        errorCode: 'payment',
        data: tokens.response.data.errors
      })
      commit(SET_IS_APPLICATION_LOADING, false, { root: true })

      return tokens
    }
    const resultToken = {
      Stripe: tokens.stripe.id
    }
    commit(SET_CREDIT_CARD_TOKEN, resultToken)
    return resultToken
  },
  [GET_PLANS]: async ({ commit }, data) => {
    const query = `?price_id=${data.priceId}`
    const response = await AxiosHttp.get('/store/available-plans/v2' + query)
      .catch(error => {
        throw error
      })
    if (response) {
      const orderedArray = Object.values(Utils.serializePlans(response)).reverse().filter(plan => plan.price > 0)
      commit(SET_PAYMENT_OPTIONS, response.paymentMethods)
      return commit(SET_PLANS, orderedArray)
    }
    return response
  },
  [GET_PLANS_MONTHLY]: async ({ commit }, data) => {
    const query = `?price_id=${data.priceId}`
    const response = await AxiosHttp.get('/store/available-plans/v2' + query)
      .catch(error => {
        throw error
      })
    if (response) {
      const toArray = Object.values(Utils.serializePlans(response))
      // const orderedArray = getPlansMonthlyFormatter(response)
      commit(SET_PLANS_MONTHLY, toArray.filter(plan => plan.price > 0))
    }
    return response
  },
  [CHANGE_PAYMENT_METHOD_BANKSLIP]: async ({ commit }, updateData) => {
    commit(SET_IS_APPLICATION_LOADING, true, { root: true })
    const response = AxiosHttp.post('/store/payment-method/change', updateData)
      .catch(error => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true })
        throw error
      }).finally(() => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true })
      })
    return response
  },
  [CHANGE_PAYMENT_METHOD_CREDIT_CARD]: async ({ commit }, data) => {
    commit(SET_IS_APPLICATION_LOADING, true, { root: true })
    const tokens = { Stripe: data.token }

    const result = await AxiosHttp.post('/store/payment-method/change', {
      newMethod: 'CreditCard',
      holder: data.holder,
      token: data.token,
      tokens: tokens,
      gateway: data.gateway,
      customerId: data.customerId,
      shopcode: data.shopcode,
      customerData: data.customerData,
      priceId: data.priceId
    })
      .catch(error => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true })
        throw error
      }).finally(() => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true })
      })

    return result
  },
  [EXEC_CHECKOUT]: async ({ commit, rootGetters }, data) => {
    const chargeData = {
      subscriber: {
        shopCode: rootGetters[`${STORE_MODULE}/${STORE}`].shopcode.toString(),
        email: rootGetters[`${STORE_MODULE}/${STORE}`].store.account,
        name: rootGetters[`${STORE_MODULE}/${STORE}`].person.owner,
        street: rootGetters[`${STORE_MODULE}/${STORE}`].storeAddress.street || '',
        number: rootGetters[`${STORE_MODULE}/${STORE}`].storeAddress.number || '',
        complement: rootGetters[`${STORE_MODULE}/${STORE}`]
          .storeAddress.complement,
        district: rootGetters[`${STORE_MODULE}/${STORE}`]
          .storeAddress.neighbourhood,
        zipCode: rootGetters[`${STORE_MODULE}/${STORE}`].storeAddress.zipcode,
        city: rootGetters[`${STORE_MODULE}/${STORE}`].storeAddress.city,
        state: rootGetters[`${STORE_MODULE}/${STORE}`].storeAddress.state || '',
        country: rootGetters[`${STORE_MODULE}/${STORE}`].storeAddress.country,
        document: rootGetters[`${STORE_MODULE}/${STORE}`].person.cpf_cnpj,
        documentType: rootGetters[`${STORE_MODULE}/${STORE}`].person.doc_type || 'NONE'
      },
      subscription: {
        customerId: rootGetters[`${STORE_MODULE}/${STORE}`].store.customerId,
        amount: data.chosenPlan.price,
        currency: data.chosenPlan.corrency,
        gateway: defineGatewayByPaymentMethod(Utils.capitalizeFirstLetter(data.paymentMethod)),
        paymentMethod: Utils.capitalizeFirstLetter(data.paymentMethod),
        priceId: data.chosenPlan.priceId,
        recurrencePeriod: recurrences[data.chosenPlan.recurrencePeriod].toUpperCase(),
        eft_code: data.paymentMethod === 'Eft' ? rootGetters[`${EFT_MODULE}/${GET_BANK_SELECTED}`] : null,
        cardToken: data.paymentMethod === 'CreditCard' ? data.creditCardData.token : null

      }
    }

    const response = await AxiosHttp.post('/checkout', chargeData)
      .catch(error => {
        throw error
      })

    if (response) {
      commit(SET_ORDER, response)
    }
    return response
  }
}

const defineGatewayByPaymentMethod = (paymentMethod) => {
  const gatewaysByPaymentMethod = {
    CreditCard: gateways.STRIPE,
    PaymentSlip: gateways.ITAU,
    RapiPago: gateways.EBANX,
    PagoFacil: gateways.EBANX,
    Oxxo: gateways.EBANX,
    Spei: gateways.EBANX,
    Pagosnet: gateways.EBANX,
    Multicaja: gateways.EBANX,
    Sencillito: gateways.EBANX,
    Servipag: gateways.EBANX,
    webpay: gateways.EBANX,
    Efecty: gateways.EBANX,
    eft: gateways.EBANX,
    safetyPay: gateways.EBANX,
    pagoEfectivo: gateways.EBANX
  }

  return gatewaysByPaymentMethod[paymentMethod] || gateways.EBANX
}
